import React, { useState, useLayoutEffect, useRef } from 'react';
import { TweenLite, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import Lab from '../lab';

export const LabsContainer = ({ labs, numcol }) => {
    let labsEl = useRef(null);
    //disable animation on first load
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useLayoutEffect(() => {
        setIsFirstLoad(false);
    }, []);
    //animate card loading
    useLayoutEffect(() => {
        const cardsFadeIn = el => {
            if (el.current !== null && !isFirstLoad) {
                TweenLite.set(el, { y: 200, opacity: 0 });
                TweenLite.to(el, 0.5, {
                    opacity: 1,
                    y: 0,
                    ease: Power3.easeOut,
                    delay: 0.3
                });
            }
        };
        cardsFadeIn(labsEl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labs]);
    //msgrid fixes for IE11, updates msgrid variables to fix grids in IE11
    const renderLab = labs.map((lab, i) => {
        return (
            <Lab
                key={i}
                lab={lab.node}
                //passes row position based on index
                msrow={Math.floor(i / numcol) + 1}
                //passes column position based on index
                mscol={(i % numcol) + 1}
            />
        );
    });
    return (
        <>
            {labs.length ? (
                <div>
                    <div className="container" id="labs-container">
                        <h2 className="content-heading">Labs</h2>
                        <br />
                        <div
                            className="post-feed"
                            ref={el => {
                                labsEl = el;
                            }}
                        >
                            {renderLab}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
LabsContainer.propTypes = {
    labs: PropTypes.array.isRequired,
    numcol: PropTypes.number.isRequired
};

export default LabsContainer;
