//Main index page
//loads all components

import React, { useState, useLayoutEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { CSSPlugin } from 'gsap/all';
import PropTypes from 'prop-types';
import { Layout } from '../components/common';
import ArticlesContainer from '../components/articlesContainer';
import LabsContainer from '../components/labsContainer';
import ProjectsContainer from '../components/projectsContainer';
import Search from '../components/search';

//data -> graphql response containing all projects, labs, articles, and their respective images
const Index = ({ data }) => {
    const { labsJSON, articlesJSON, projectsJSON } = data;
    const defaultData = {
        articles: articlesJSON.edges,
        labs: labsJSON.edges,
        projects: projectsJSON.edges
    };
    const [postData, setPostData] = useState(defaultData);
    const [numcol, setNumcol] = useState(3);
    // eslint-disable-next-line no-unused-vars
    const plugins = [CSSPlugin];

    //updates msgrid variables to fix grids in IE11
    useLayoutEffect(() => {
        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        function updateSize() {
            if (isIE) {
                if (window.innerWidth < 680) {
                    setNumcol(1);
                } else if (window.innerWidth < 980) {
                    setNumcol(2);
                } else {
                    setNumcol(3);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    //filter project/article/lab instances based on search query
    const searchFilter = lowerCasedQuery => {
        if (lowerCasedQuery.length) {
            let filteredData = {};
            Object.keys(defaultData).forEach(function(postCategory) {
                //performs linear search through all of post types (article, lab, projects)
                filteredData[postCategory] = defaultData[postCategory].filter(post => {
                    return Object.values(post.node).some(
                        postAttribute =>
                            typeof postAttribute === 'string' &&
                            postAttribute !== null &&
                            postAttribute.toLowerCase().includes(lowerCasedQuery)
                    );
                });
            });
            return filteredData;
        } else {
            return defaultData;
        }
    };

    //apply the search filter
    const setFilterResults = userQuery => {
        const lowerCasedQuery = userQuery.toString().toLowerCase();
        const displayData = searchFilter(lowerCasedQuery);
        setPostData(displayData);
    };

    //check if there are search results
    const isResultEmpty = !(
        Object.keys(postData.articles).length ||
        Object.keys(postData.labs).length ||
        Object.keys(postData.projects).length
    );
    return (
        <>
            <Layout isHome={true}>
                <Search setFilterResults={setFilterResults} />
                <div>
                    <ProjectsContainer projects={postData.projects} numcol={numcol} />
                    <ArticlesContainer articles={postData.articles} numcol={numcol} />
                    <LabsContainer labs={postData.labs} numcol={numcol} />
                </div>
                {isResultEmpty ? <h2 className="content-heading empty-result">No Results</h2> : null}
            </Layout>
        </>
    );
};

Index.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired,
    pageContext: PropTypes.object
};

//graphql query that pulls data from json files
//images are stored as path relative to /src/data folder
//images are automatically processed with sharp
//response contains image objects
const DefaultArticleQuery = props => (
    <StaticQuery
        query={graphql`
            query IndexQuery {
                articlesJSON: allArticlesJson {
                    edges {
                        node {
                            title
                            description
                            url
                            profile_image
                            authorName
                            type
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
                labsJSON: allLabsJson {
                    edges {
                        node {
                            title
                            description
                            url
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
                projectsJSON: allProjectsJson {
                    edges {
                        node {
                            title
                            icon
                            repo
                            description
                            install
                            link
                            package
                        }
                    }
                }
            }
        `}
        render={data => <Index data={data} {...props} />}
    />
);

export default DefaultArticleQuery;
