import axios from 'axios';
var currentTime = new Date();
export const fetchGithubData = async project => {
    //sends new request to api and stores it in localstorage if it has been less than a hour since last request
    //convert dates to miliseconds, and see if the difference is less than 3600000 (number of miliseconds in an hour)
    const localStorageTime = localStorage.getItem('storedTime');
    if (localStorageTime && Date.parse(currentTime) - Date.parse(localStorageTime) < 3600000) {
        return JSON.parse(localStorage.getItem(project));
    } else {
        const response = await axios.get(`https://api.github.com/repos/jam3/${project}`);
        //stores response in local storage as the response is large and there is a limited request amount.
        const retStars = await response.data.stargazers_count;
        localStorage.setItem(project, JSON.stringify(retStars));
        localStorage.setItem('storedTime', currentTime);
        return retStars;
    }
};

export const fetchNpmData = async project => {
    const response = await axios(`https://api.npmjs.org/downloads/point/last-week/${project}`);
    const data = await response.data;
    return data.downloads;
};
