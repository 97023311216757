//Project card component
//each project is an instance of this componenet

import React, { useState, useEffect } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';
import mediumIcon from '../../images/medium-icon-128.png';
import githubIcon from '../../images/github-icon-64b.png';
import npmIcon from '../../images/npm-icon-64b.png';
import { fetchGithubData, fetchNpmData } from '../fetchData/FetchData';

//post -> object containing graphql response from json, contains text, image
//msrow -> the grid row number (for IE11 grid compatability)
//mscol -> the grid column number (for IE11 grid compatability)
const Project = ({ post, msrow, mscol }) => {
    const [weeklyDownload, setWeeklyDownload] = useState('0');
    const [numStars, setNumStars] = useState('0');
    //get number of stars on Github repo
    useEffect(() => {
        const getRepoData = async () => {
            const githubName = await post.repo;
            if (post.icon === 'github') {
                const res = await fetchGithubData(githubName);
                setNumStars(res);
            }
        };

        getRepoData();
    }, [post.icon, post.repo]);
    //get number of weekly downloads on NPM package
    useEffect(() => {
        const getWeeklyData = async () => {
            const npmName = await post.package;
            if (post.icon === 'npm') {
                const res = await fetchNpmData(npmName);
                setWeeklyDownload(res);
            }
        };

        getWeeklyData();
    }, [post.icon, post.package]);
    //select which icon to use
    var whatIcon;
    var iconAlt;
    switch (post.icon) {
        case 'medium':
            whatIcon = mediumIcon;
            iconAlt = 'Medium';
            break;
        case 'github':
            whatIcon = githubIcon;
            iconAlt = 'Github';
            break;
        case 'npm':
            whatIcon = npmIcon;
            iconAlt = 'npm';
            break;
        default:
            whatIcon = null;
            iconAlt = null;
            break;
    }
    //display the number of Github repo stars
    const renderStars = () => {
        return (
            <div className="project-extra">
                <p className="project-downloads">
                    <svg width="16" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path
                                d="M3.71596 10.2414L2.87129 15.4194C2.80396 15.8307 3.24329 16.1394 3.60662 15.9374L7.99996 13.5094L12.3933 15.938C12.7533 16.138 13.1966 15.8354 13.1286 15.42L12.284 10.242L15.8573 6.57936C16.1406 6.28936 15.978 5.79736 15.5753 5.73603L10.6566 4.9847L8.45196 0.288696C8.28729 -0.0619709 7.71196 -0.0619709 7.54729 0.288696L5.34329 4.98403L0.424621 5.73536C0.0206212 5.79736 -0.140712 6.2887 0.142621 6.5787L3.71596 10.2414Z"
                                fill="black"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    &nbsp;
                    {numStars}
                </p>
            </div>
        );
    };
    //display the number of weekly NPM downloads
    const renderInstall = () => {
        const downloads = weeklyDownload;
        return (
            <div className="project-extra">
                <p className="project-downloads">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -16 512.00046 512" width="20" height="20">
                        <defs />
                        <path d="M413.492188 128.910156C396.199219 42.144531 311.84375-14.171875 225.078125 3.121094 161.617188 15.769531 111.996094 65.359375 99.308594 128.8125 37.789062 135.902344-6.339844 191.519531.746094 253.042969c6.523437 56.621093 54.480468 99.339843 111.476562 99.300781h80.09375c8.847656 0 16.019532-7.171875 16.019532-16.019531 0-8.847657-7.171876-16.019531-16.019532-16.019531h-80.09375c-44.238281-.261719-79.882812-36.332032-79.625-80.566407.261719-44.238281 36.332032-79.886719 80.570313-79.625 8.164062 0 15.023437-6.140625 15.921875-14.257812C137.222656 75.550781 200.8125 25.148438 271.121094 33.28125c59.109375 6.835938 105.738281 53.464844 112.574218 112.574219 1.34375 8.261719 8.5 14.3125 16.867188 14.257812 44.238281 0 80.097656 35.859375 80.097656 80.097657 0 44.234374-35.859375 80.09375-80.097656 80.09375h-80.09375c-8.847656 0-16.019531 7.171874-16.019531 16.019531 0 8.847656 7.171875 16.019531 16.019531 16.019531h80.097656C462.492188 351.957031 512.382812 301.441406 512 239.515625c-.351562-56.394531-42.53125-103.753906-98.507812-110.605469zm0 0" />
                        <path d="M313.019531 385.183594l-40.609375 40.621094V224.191406c0-8.847656-7.171875-16.019531-16.015625-16.019531-8.847656 0-16.019531 7.171875-16.019531 16.019531v201.613282l-40.609375-40.621094c-6.144531-6.367188-16.289063-6.542969-22.652344-.394532-6.363281 6.144532-6.539062 16.285157-.394531 22.648438.132812.136719.261719.265625.394531.394531l67.9375 67.953125c1.484375 1.480469 3.242188 2.65625 5.175781 3.460938 3.941407 1.667968 8.390626 1.667968 12.335938 0 1.933594-.804688 3.691406-1.980469 5.171875-3.460938l67.9375-67.953125c6.363281-6.144531 6.539063-16.285156.394531-22.648437-6.148437-6.363282-16.289062-6.539063-22.652344-.394532-.132812.128907-.265624.257813-.394531.394532zm0 0" />
                    </svg>
                    &nbsp;
                    {downloads}
                </p>
            </div>
        );
    };
    return (
        <div style={{ msGridColumn: `${mscol}`, msGridRow: `${msrow}` }} className="project card">
            <div className="project-main">
                <div className="project-header">
                    <img className="project-icon" src={whatIcon} alt={iconAlt} />
                    <h3 className=" project-title card-title">
                        <OutboundLink
                            href={post.link}
                            target="_blank"
                            style={{ color: 'inherit' }}
                            rel="noopener noreferrer"
                            eventCategory="Projects"
                        >
                            {post.title}
                        </OutboundLink>
                    </h3>
                </div>
                <section className="project-excerpt card-excerpt">
                    {post.description.length > 70 ? `${post.description.slice(0, 69)}...` : post.description}
                </section>
            </div>
            {post.icon === 'github' ? renderStars() : renderInstall()}
        </div>
    );
};

Project.propTypes = {
    post: PropTypes.shape({
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string,
        install: PropTypes.string
    }).isRequired,
    msrow: PropTypes.number.isRequired,
    mscol: PropTypes.number.isRequired
};

export default Project;
