//Lab card component
//each lab is an instance of this componenet
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
//lab -> object containing graphql response from json, contains text, image
//msrow -> the grid row number (for IE11 grid compatability)
//mscol -> the grid column number (for IE11 grid compatability)
const Lab = ({ lab, msrow, mscol }) => {
    return (
        <div style={{ msGridColumn: `${mscol}`, msGridRow: `${msrow}` }} className="lab-card">
            <header className="lab-card-header">
                <h3 className="lab-card-title">
                    <OutboundLink
                        href={lab.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit' }}
                        eventCategory="Labs"
                    >
                        {lab.title}
                    </OutboundLink>
                </h3>
                <OutboundLink
                    href={lab.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit' }}
                    aria-label={lab.title || lab.description || lab.url}
                    eventCategory="Labs"
                >
                    <BackgroundImage
                        className="lab-card-image"
                        fluid={lab.image.childImageSharp.fluid}
                    ></BackgroundImage>
                </OutboundLink>
            </header>
            <section className="lab-card-excerpt">
                {lab.description.length > 70 ? `${lab.description.slice(0, 69)}...` : lab.description}
            </section>
        </div>
    );
};

Lab.propTypes = {
    lab: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.object
    }).isRequired,
    msrow: PropTypes.number.isRequired,
    mscol: PropTypes.number.isRequired
};

export default Lab;
