//Article card component
//each article is an instance of this componenet

import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';
import threeLogo from '../../images/threeLogo.jpeg';
import devtoLogo from '../../images/devto.svg';
import mediumLogo from '../../images/medium-icon-128.png';

//article -> object containing graphql response from json, contains text, image
//msrow -> the grid row number (for IE11 grid compatability)
//mscol -> the grid column number (for IE11 grid compatability)
const Article = ({ article, msrow, mscol }) => {
    return (
        <div style={{ msGridColumn: `${mscol}`, msGridRow: `${msrow}` }} className="article-card">
            <div className="article-main">
                <div href={article.url} target="_blank" rel="noopener noreferrer" className="article-card-header">
                    {/* component for background image gatsby-transformer-sharp support */}
                    <BackgroundImage
                        className="article-card-image"
                        fluid={article.image.childImageSharp.fluid}
                    ></BackgroundImage>
                    <h3 className="article-card-title">
                        <OutboundLink
                            href={article.url}
                            target="_blank"
                            style={{ color: 'inherit' }}
                            rel="noopener noreferrer"
                            eventCategory="Articles"
                        >
                            {article.title}
                        </OutboundLink>
                    </h3>
                </div>
                <section className="article-card-excerpt">
                    {article.description.length > 55 ? `${article.description.slice(0, 54)}...` : article.description}
                </section>
            </div>
            <div className="article-card-footer">
                <div className="article-card-footer-left">
                    <div className="article-card-avatar">
                        {article.profile_image ? (
                            <img className="default-avatar" src={article.profile_image} alt={article.authorName} />
                        ) : (
                            <img
                                className="default-avatar"
                                src={article.type ? (article.type === 'medium' ? mediumLogo : devtoLogo) : threeLogo}
                                alt={article.type ? (article.type === 'medium' ? 'Medium' : 'dev.to') : 'jam3'}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://medium.com/@Jam3"
                            />
                        )}
                    </div>
                </div>
                <p className="article-card-footer-right">{article.authorName ? article.authorName : 'Jam3'}</p>
            </div>
        </div>
    );
};

Article.propTypes = {
    article: PropTypes.shape({
        url: PropTypes.string.isRequired,
        image: PropTypes.object,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        profile_image: PropTypes.string,
        authorName: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    msrow: PropTypes.number.isRequired,
    mscol: PropTypes.number.isRequired
};

export default Article;
