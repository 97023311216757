//Renders all articles
//article componenet is mapped to array containing all articles

import React, { useState, useLayoutEffect, useRef } from 'react';
import { TweenLite, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import Article from '../article';

export const ArticlesContainer = ({ articles, numcol }) => {
  let articlesEl = useRef(null);
  //disable animation on first load
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useLayoutEffect(() => {
    setIsFirstLoad(false);
  }, []);
  //animate card loading
  useLayoutEffect(() => {
    const cardsFadeIn = el => {
      if (el.current !== null && !isFirstLoad) {
        TweenLite.set(el, { y: 200, opacity: 0 });
        TweenLite.to(el, 0.5, {
          opacity: 1,
          y: 0,
          ease: Power3.easeOut,
          delay: 0.3
        });
      }
    };
    cardsFadeIn(articlesEl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);
  //updates msgrid variables to fix grids in IE11
  const renderArticle = articles.map((article, i) => {
    return (
      <Article
        key={i}
        article={article.node}
        //passes row position based on index
        msrow={Math.floor(i / numcol) + 1}
        //passes column position based on index
        mscol={(i % numcol) + 1}
      />
    );
  });
  return (
    <>
      {articles.length ? (
        <div>
          <div className="container" id="articles-container">
            <h2 className="content-heading">Articles</h2>
            <br />
            <div
              className="post-feed"
              ref={el => {
                articlesEl = el;
              }}
            >
              {renderArticle}
            </div>
            <span className="content-footer">
              Find more in our{' '}
              <a href="https://medium.com/@Jam3" target="_blank" rel="noopener noreferrer">
                Medium
              </a>{' '}
              and{' '}
              <a href="https://dev.to/jam3" target="_blank" rel="noopener noreferrer">
                dev.to
              </a>{' '}
              accounts
            </span>
          </div>
          <br />
        </div>
      ) : null}
    </>
  );
};

ArticlesContainer.propTypes = {
  articles: PropTypes.array.isRequired,
  numcol: PropTypes.number.isRequired
};

export default ArticlesContainer;
