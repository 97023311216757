import dompurify from 'dompurify';
import { isBrowser } from './basic-functions';

/**
 * DOM Sanitizer to protect against untrust inputs and XSS attacks
 *
 * @param {string} [dirtyInput=''] - Input to sanitize
 */
function sanitizer(dirtyInput, ...options) {
  let DOMPurifyInstance;
  if (isBrowser) {
    DOMPurifyInstance = dompurify;
  } else {
    // eslint-disable-next-line no-eval
    const jsdom = eval(`require('jsdom')`); // this is needed to make it work with Webpack
    const JSDOM = jsdom.JSDOM;
    const { window } = new JSDOM(`...`);
    DOMPurifyInstance = dompurify(window);
  }

  return DOMPurifyInstance.sanitize(dirtyInput, options);
}

export default sanitizer;
