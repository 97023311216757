//Renders all projects
//project componenet is mapped to array containing all projects

import React, { useState, useLayoutEffect, useRef } from 'react';
import { TweenLite, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import Project from '../project';

export const ProjectsContainer = ({ projects, numcol }) => {
  let ProjectsEl = useRef(null);
  //disable animation on first load
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useLayoutEffect(() => {
    setIsFirstLoad(false);
  }, []);
  //animate card loading
  useLayoutEffect(() => {
    const cardsFadeIn = el => {
      if (el.current !== null && !isFirstLoad) {
        TweenLite.set(el, { y: 200, opacity: 0 });
        TweenLite.to(el, 0.5, {
          opacity: 1,
          y: 0,
          ease: Power3.easeOut,
          delay: 0.3
        });
      }
    };
    cardsFadeIn(ProjectsEl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  //msgrid fixes for IE11
  const renderProjects = projects.map((posts, i) => (
    <Project
      key={i}
      post={posts.node}
      //passes row position based on index
      msrow={Math.floor(i / numcol) + 1}
      //passes column position based on index
      mscol={(i % numcol) + 1}
    />
  ));
  return (
    <>
      {projects.length ? (
        <div>
          <div className="container" id="projects-container">
            <h2 className="content-heading">Projects</h2>
            <br />
            <section
              className="post-feed"
              ref={el => {
                ProjectsEl = el;
              }}
            >
              {renderProjects}
            </section>
            <span className="content-footer">
              Find more in our{' '}
              <a href="https://github.com/Jam3" target="_blank" rel="noopener noreferrer">
                Github
              </a>{' '}
              and{' '}
              <a href="https://www.npmjs.com/org/jam3" target="_blank" rel="noopener noreferrer">
                NPM
              </a>{' '}
              accounts
            </span>
          </div>
          <br />
        </div>
      ) : null}
    </>
  );
};

ProjectsContainer.propTypes = {
  projects: PropTypes.array.isRequired,
  numcol: PropTypes.number.isRequired
};

export default ProjectsContainer;
