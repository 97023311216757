import React from 'react';
import PropTypes from 'prop-types';
import SearchSVG from '../../images/svgs/search.svg';
import sanitizer from '../../utils/sanitize';

const Search = ({ setFilterResults }) => {
  return (
    <div className="search-wrapper container">
      <div>
        <SearchSVG className="search-svg" />
        <label id="search-label" htmlFor="search-bar">
          Search
        </label>
        <input
          id="search-bar"
          placeholder="Search"
          style={{ float: 'left' }}
          onChange={e => setFilterResults(sanitizer(e.currentTarget.value))}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  setFilterResults: PropTypes.func.isRequired
};
export default Search;
